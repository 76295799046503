import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        component: () =>
            import('@/views/dashboard/login'),
        name: "login",
        meta: {
            guest: true,
        },
    },
    {
        path: '/dashboard',
        component: () =>
            import('@/views/dashboard/Index'),
        meta: {
            requiresAuth: true,
        },
        children: [
            // Dashboard
            {
                name: 'Dashboard',
                path: '',
                component: () =>
                    import('@/views/dashboard/Dashboard'),
            },
            // Pages
            {
                name: 'User Profile',
                path: 'pages/user',
                component: () =>
                    import('@/views/dashboard/pages/UserProfile'),
            },
            {
                name: 'Warehouse Approve',
                path: 'pages/warehouseapproval',
                component: () =>
                    import('@/views/dashboard/pages/warehouseApproveProduct'),
            },
            {
                name: 'Manager User Details',
                path: 'pages/manageruserdetails',
                component: () =>
                    import('@/views/dashboard/pages/ManagerDetails'),
            },
            {
                name: 'Block Unblock',
                path: 'pages/block',
                component: () =>
                    import('@/views/dashboard/pages/BlockUnblock'),
            },
            {
                name: 'Purchase Invoice',
                path: 'pages/mother-invoices',
                component: () =>
                    import('@/views/dashboard/pages/MotherSiteOrderInvoice'),
            },

            {
                name: 'Flash Sale',
                path: 'pages/flash_sale',
                component: () =>
                    import('@/views/dashboard/pages/FlashSale'),
            },
            {
                name: 'Payment',
                path: 'pages/payment',
                component: () =>
                    import('@/views/dashboard/pages/payment/index'),
            },
            {
                name: 'Payment Approval',
                path: 'pages/payment/approve-transaction',
                component: () =>
                    import('@/views/dashboard/pages/payment/ApproveTansaction'),
            },
            {
                name: 'Payment Approval',
                path: 'pages/payment/approve-seller-transaction',
                component: () =>
                    import('@/views/dashboard/pages/payment/ApproveSellerTransaction'),
            },
            {
                name: 'Purchase Invoice',
                path: 'pages/mother-purchase-invoices',
                component: () =>
                    import('@/views/dashboard/pages/MotherSiteOrderPurchaseInvoice'),
            },
            {
                name: 'Sales',
                path: 'pages/sales',
                component: () =>
                    import('@/views/dashboard/pages/Sales'),
            },
            {
                name: 'Sales Report',
                path: 'pages/salesreport',
                component: () =>
                    import('@/views/dashboard/pages/SalesReport'),
            },
            {
                name: 'Warehouse Report',
                path: 'pages/warehousereport',
                component: () =>
                    import('@/views/dashboard/pages/WarehouseReport'),
            },
            {
                name: 'Money Report',
                path: 'pages/moneypdf',
                component: () =>
                    import('@/views/dashboard/pages/MoneyRecipt'),
            },
            {
                name: 'POS Management',
                path: 'pages/pos',
                component: () =>
                    import('@/views/dashboard/pages/POS'),
            },
            {
                name: 'View Chalan',
                path: 'pages/admin_chalan',
                component: () =>
                    import('@/views/dashboard/pages/AdminChalan'),
            },
            {
                name: 'POS Order',
                path: 'pages/pos_order',
                component: () =>
                    import('@/views/dashboard/pages/POS_Order'),
            },
            {
                name: 'Imported Product',
                path: 'pages/imported_product',
                component: () =>
                    import('@/views/dashboard/pages/ImportedProduct'),
            },
            {
                name: 'Import Specification',
                path: 'pages/import_specification',
                component: () =>
                    import('@/views/dashboard/pages/ImportSpecification'),
            },
            {
                name: 'POS Details',
                path: 'pages/posdetails',
                component: () =>
                    import('@/views/dashboard/pages/POSDetails'),
            },
            {
                name: 'Product Order Invoice',
                path: 'pages/orderinvoice_updated',
                component: () =>
                    import('@/views/dashboard/pages/OrderInvoice_update'),
            },
            {
                name: 'New Invoice Page',
                path: 'pages/newInoice_page',
                component: () =>
                    import('@/views/dashboard/pages/NewInvoicePage'),
            },
           
            {
                name: 'Shop Report',
                path: 'pages/shopreport',
                component: () =>
                    import('@/views/dashboard/pages/ShopReport'),
            },
            {
                name: 'Rating-Review',
                path: 'pages/review',
                component: () =>
                    import('@/views/dashboard/pages/RatingReview.vue'),
            },
            {
                name: 'Analytics',
                path: 'pages/analytics',
                component: () =>
                    import('@/views/dashboard/pages/Analytics'),
            },
            {
                name: 'Product Details',
                path: 'pages/productdetails',
                component: () =>
                    import('@/views/dashboard/pages/ProductDetails'),
            },
            {
                name: 'Group Products',
                path: 'pages/group-products',
                component: () =>
                    import('@/views/dashboard/pages/GroupProducts'),
            },
            {
                name: 'Edit Product Image',
                path: 'pages/edit-product-image',
                component: () =>
                    import('@/views/dashboard/pages/EditProductImage'),
            },
            {
                name: 'Invoice',
                path: 'pages/invoice',
                component: () =>
                    import('@/views/dashboard/pages/Invoice'),
            },
            {
                name: 'Order Invoice',
                path: 'pages/orderinvoice',
                component: () =>
                    import('@/views/dashboard/pages/OrderInvoice'),
            },
            {
                name: 'Mother Invoice',
                path: 'pages/motherinvoice',
                component: () =>
                    import('@/views/dashboard/pages/MotherInvoice'),
            },
            {
                name: 'Mother Invoice',
                path: 'pages/motherinvoice_updated',
                component: () =>
                    import('@/views/dashboard/pages/MotherInvoiceUpdated'),
            },
            {
                name: 'Confirm Product',
                path: 'pages/confirmproduct',
                component: () =>
                    import('@/views/dashboard/pages/ConfirmProduct'),
            },
            {
                name: 'Product',
                path: 'pages/product',
                component: () =>
                    import('@/views/dashboard/pages/Product'),
            },
            {
                name: 'Group Product',
                path: 'pages/groupproduct',
                component: () =>
                    import('@/views/dashboard/pages/GroupProduct'),
            },
            {
                name: 'Add Product',
                path: 'pages/addproduct',
                component: () =>
                    import('@/views/dashboard/pages/AddProduct'),
            },
            {
                name: 'Advertisement',
                path: 'pages/advertisement',
                component: () =>
                    import('@/views/dashboard/pages/Advertisement'),
            },

            {
                name: 'Add Group Product',
                path: 'pages/addgroupproduct',
                component: () =>
                    import('@/views/dashboard/pages/AddGroupProduct'),
            },
            {
                name: 'Company Information',
                path: 'pages/companyinfo',
                component: () =>
                    import('@/views/dashboard/pages/CompanyInfo'),
            },
            {
                name: 'Company Information',
                path: 'pages/addcompanyinfo',
                component: () =>
                    import('@/views/dashboard/pages/AddCompanyInfo'),
            },
            {
                name: 'Contract Us',
                path: 'pages/contractus',
                component: () =>
                    import('@/views/dashboard/pages/ContractUs'),
            },
            {
                name: 'FAQ',
                path: 'pages/faq',
                component: () =>
                    import('@/views/dashboard/pages/FAQ'),
            },
            {
                name: 'Brand',
                path: 'pages/brand',
                component: () =>
                    import('@/views/dashboard/pages/Brand'),
            },
            {
                name: 'Brand',
                path: 'pages/seller_specifications',
                component: () =>
                    import('@/views/dashboard/pages/SellerSpecification'),
            },
            {
                name: 'Report',
                path: 'pages/report',
                component: () =>
                    import('@/views/dashboard/pages/SharedProduct'),
            },

            {
                name: 'Mothersite Product Report',
                path: 'pages/mothersite-report',
                component: () =>
                    import('@/views/dashboard/pages/MothersiteProductReport'),
            },
            // {
            //     name: 'Report',
            //     path: 'pages/report',
            //     component: () =>
            //         import ('@/views/dashboard/pages/MotherSiteSharedProduct'),
            // },
            {
                name: 'Approve Seller',
                path: 'pages/approve-products',
                component: () =>
                    import('@/views/dashboard/pages/ApproveSellerProduct'),
            },
            {
                name: 'Warehouse',
                path: 'pages/warehouse',
                component: () =>
                    import('@/views/dashboard/pages/Warehouse'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/warehousedetails',
                component: () =>
                    import('@/views/dashboard/pages/WarehouseDetails'),
            },
            {
                name: 'Shop Details',
                path: 'pages/shopdetails',
                component: () =>
                    import('@/views/dashboard/pages/ShopDetails'),
            },
            {
                name: 'Customer Invoice',
                path: 'pages/cuscomerinvoice',
                component: () =>
                    import('@/views/dashboard/pages/CustomerInvoice'),
            },
            {
                name: 'CRM Chat',
                path: 'pages/chat',
                component: () =>
                    import('@/views/dashboard/pages/CRMChat'),
            },
            {
                name: 'Notes',
                path: 'pages/notes',
                component: () =>
                    import('@/views/dashboard/pages/CRMNotes'),
            },
            {
                name: 'Order',
                path: 'pages/order',
                component: () =>
                    import('@/views/dashboard/pages/Order'),
            },
            {
                name: 'Merchant',
                path: 'pages/merchantreport',
                component: () =>
                    import('@/views/dashboard/pages/MerchentReport'),
            },
            {
                name: 'Merchant Transaction',
                path: 'pages/merchant/transactions',
                component: () =>
                    import('@/views/dashboard/pages/MerchentTransactionReport'),
            },
            {
                name: 'Daily Product Report',
                path: 'pages/daily_report',
                component: () =>
                    import('@/views/dashboard/pages/DailyReport'),
            },
            {
                name: 'Daily Product Report',
                path: 'pages/stock_report',
                component: () =>
                    import('@/views/dashboard/pages/StockReport'),
            },
            {
                name: 'New Invoice',
                path: 'pages/new_invoice',
                component: () =>
                    import('@/views/dashboard/pages/NewOrderInvoice'),
            },
            {
                name: 'New Invoice',
                path: 'pages/new_invoiceUpdated',
                component: () =>
                    import('@/views/dashboard/pages/NewOrderInvoiceAzizSir'),
            },
            {
                name: 'Merchant Details',
                path: 'pages/merchantdetails',
                component: () =>
                    import('@/views/dashboard/pages/MerchentDetails'),
            },
            {
                name: 'Sales',
                path: 'pages/sales',
                component: () =>
                    import('@/views/dashboard/pages/Sales'),
            },
            // {
            //     name: 'Pending Product',
            //     path: 'pages/pendingproducts',
            //     component: () =>
            //         import('@/views/dashboard/pages/SellerProduct'),
            // },

            {
                name: 'Pending Product',
                path: 'pages/pendingproducts',
                component: () =>
                    import('@/views/dashboard/pages/MerchantProduct'),
            },

            {
                name: 'Mother Product',
                path: 'pages/mother-products',
                component: () =>
                    import('@/views/dashboard/pages/MotherSiteSharedProduct'),
            },
            {
                name: 'UnSahred Product',
                path: 'pages/unshared-products',
                component: () =>
                    import('@/views/dashboard/pages/UnsharedProduct'),
            },
            {
                name: 'Unpublished Product',
                path: 'pages/unpublished-products',
                component: () =>
                    import('@/views/dashboard/pages/UnpublishedProduct'),
            },
            {
                name: 'Commission',
                path: 'pages/commission',
                component: () =>
                    import('@/views/dashboard/pages/commission/index'),
            },
            {
                name: 'specification',
                path: 'pages/specification',
                component: () =>
                    import('@/views/dashboard/pages/Specification'),
            },
            {
                name: 'Specification Barcode',
                path: 'pages/specification_barcode',
                component: () =>
                    import('@/views/dashboard/pages/SpecificationBarcode'),
            },
            {
                name: 'addproce',
                path: 'pages/add-price',
                component: () =>
                    import('@/views/dashboard/pages/AddPrice'),
            },
            {
                name: 'viewcomments',
                path: 'pages/viewcomments',
                component: () =>
                    import('@/views/dashboard/pages/ViewComments'),
            },
            {
                name: 'groupspecification',
                path: 'pages/groupspecification',
                component: () =>
                    import('@/views/dashboard/pages/GroupSpecification'),
            },
            // {
            //   name: 'Order Details',
            //   path: 'pages/orderdetails',
            //   component: () => import('@/views/dashboard/pages/OrderDetail'),
            // },

            {
                name: 'Admin Invoices',
                path: 'pages/invoices',
                component: () =>
                    import('@/views/dashboard/pages/AdminInvoices'),
            },

            {
                name: 'Admin Order Invoice Approval',
                path: 'pages/approveinvoice',
                component: () =>
                    import('@/views/dashboard/pages/InvoiceApproval'),
            },


            {
                name: 'Banner',
                path: 'pages/banner',
                component: () =>
                    import('@/views/dashboard/pages/Banner'),
            },

            {
                name: 'Add Banner',
                path: 'pages/addbanner',
                component: () =>
                    import('@/views/dashboard/pages/AddBanner'),
            },

            {
                name: 'Delivery',
                path: 'pages/api/delivery',
                component: () =>
                    import('@/views/dashboard/pages/api/Delivery'),
            },
            {
                name: 'CRM',
                path: 'pages/api/crm',
                component: () =>
                    import('@/views/dashboard/pages/api/CRM'),
            },
            {
                name: 'Email Configure',
                path: 'pages/emailconfig',
                component: () =>
                    import('@/views/dashboard/pages/EmailConfigure'),
            },
            {
                name: 'Supports',
                path: 'pages/supports',
                component: () =>
                    import('@/views/dashboard/pages/Supports'),
            },
            {
                name: 'POS Report',
                path: 'pages/pos_report',
                component: () =>
                    import('@/views/dashboard/pages/POSReport'),
            },
            {
                name: 'POS Invoice',
                path: 'pages/pos_invoice',
                component: () =>
                    import('@/views/dashboard/pages/POSInvoice'),
            },
            {
                name: 'POS Invoice',
                path: 'pages/pos_invoice_updated',
                component: () =>
                    import('@/views/dashboard/pages/POSInvoice_Aziz_Sir'),
            },
            {
                name: 'Find Invoice',
                path: 'pages/find-invoice',
                component: () =>
                    import('@/views/dashboard/pages/FindInvoice'),
            },
            {
                name: 'Setting',
                path: 'pages/setting',
                component: () =>
                    import('@/views/dashboard/pages/Setting'),
            },
            {
                name: 'Notifications',
                path: 'components/notifications',
                component: () =>
                    import('@/views/dashboard/component/Notifications'),
            },

            {
                name: 'Icons',
                path: 'components/icons',
                component: () =>
                    import('@/views/dashboard/component/Icons'),
            },
            // Tables
            {
                name: 'Regular Tables',
                path: 'tables/regular-tables',
                component: () =>
                    import('@/views/dashboard/tables/RegularTables'),
            },
            // Maps
            {
                name: 'Google Maps',
                path: 'maps/google-maps',
                component: () =>
                    import('@/views/dashboard/maps/GoogleMaps'),
            },
            // Upgrade
            {
                name: 'Upgrade',
                path: 'upgrade',
                component: () =>
                    import('@/views/dashboard/Upgrade'),
            },

            {
                name: 'Coupon',
                path: 'pages/coupon',
                component: () =>
                    import('@/views/dashboard/pages/AddCoupon'),
            },

            // All Users
            {
                name: 'user',
                path: 'pages/alluser',
                component: () =>
                    import('@/views/dashboard/pages/Users'),
            },

            // All Staffs (named partners)
            {
                name: 'user',
                path: 'pages/allpartner',
                component: () =>
                    import('@/views/dashboard/pages/Partners'),
            },

            //all merchant list
            {
                name: 'merchants',
                path: 'pages/merchants',
                component: () =>
                    import('@/views/dashboard/pages/Merchants'),
            },


            // Expense related routes
            {
                name: 'AllExpenses',
                path: 'pages/expenses',
                component: () =>
                    import('@/views/dashboard/pages/expenses/AllExpenses'),
            },
            {
                name: 'CreateVoucher',
                path: 'pages/create-voucher',
                component: () =>
                    import('@/views/dashboard/pages/expenses/CreateVoucher'),
            },
            {
                name: 'ShowExpenses',
                path: 'pages/all-expenses',
                component: () =>
                    import('@/views/dashboard/pages/expenses/ShowExpenses'),
            },
            {
                name: 'ExpenseReport',
                path: 'pages/expense-report',
                component: () =>
                    import('@/views/dashboard/pages/expenses/ExpenseReport'),
            },
            {
                name: 'ExpenseOrderDetails',
                path: 'pages/expense-report/order',
                component: () =>
                    import('@/views/dashboard/pages/expenses/OrderDetails'),
            },
            {
                name: 'ExpenseDetails',
                path: 'pages/expense-report/expense',
                component: () =>
                    import('@/views/dashboard/pages/expenses/ExpenseDetails'),
            },
        ],
    },

    {
        path: '/supportdashboard',
        component: () =>
            import('@/views/supportdashboard/Index'),
        meta: {
            supportAuth: true,
        },
        children: [
            // supportdashboard
            {
                name: 'supportdashboard',
                path: '',
                component: () =>
                    import('@/views/supportdashboard/Dashboard'),
            },
            // Pages


            {
                name: 'Manager User Details',
                path: 'pages/manageruserdetails',
                component: () =>
                    import('@/views/supportdashboard/pages/ManagerDetails'),
            },
            {
                name: 'Warehouse Approve',
                path: 'pages/warehouseapproval',
                component: () =>
                    import('@/views/dashboard/pages/warehouseApproveProduct'),
            },

            {
                name: 'Block Unblock',
                path: 'pages/block',
                component: () =>
                    import('@/views/supportdashboard/pages/BlockUnblock'),
            },


            {
                name: 'Flash Sale',
                path: 'pages/flash_sale',
                component: () =>
                    import('@/views/supportdashboard/pages/FlashSale'),
            },


            {
                name: 'Sales',
                path: 'pages/sales',
                component: () =>
                    import('@/views/supportdashboard/pages/Sales'),
            },
            {
                name: 'Sales Report',
                path: 'pages/salesreport',
                component: () =>
                    import('@/views/supportdashboard/pages/SalesReport'),
            },
            {
                name: 'Warehouse Report',
                path: 'pages/warehousereport',
                component: () =>
                    import('@/views/supportdashboard/pages/WarehouseReport'),
            },
            {
                name: 'Money Report',
                path: 'pages/moneypdf',
                component: () =>
                    import('@/views/supportdashboard/pages/MoneyRecipt'),
            },
            {
                name: 'POS Management',
                path: 'pages/pos',
                component: () =>
                    import('@/views/supportdashboard/pages/POS'),
            },
            {
                name: 'View Chalan',
                path: 'pages/admin_chalan',
                component: () =>
                    import('@/views/supportdashboard/pages/AdminChalan'),
            },
            {
                name: 'POS Order',
                path: 'pages/pos_order',
                component: () =>
                    import('@/views/supportdashboard/pages/POS_Order'),
            },
            {
                name: 'Imported Product',
                path: 'pages/imported_product',
                component: () =>
                    import('@/views/supportdashboard/pages/ImportedProduct'),
            },
            {
                name: 'Import Specification',
                path: 'pages/import_specification',
                component: () =>
                    import('@/views/supportdashboard/pages/ImportSpecification'),
            },
            {
                name: 'POS Details',
                path: 'pages/posdetails',
                component: () =>
                    import('@/views/supportdashboard/pages/POSDetails'),
            },
            {
                name: 'Product Order Invoice',
                path: 'pages/orderinvoice_updated',
                component: () =>
                    import('@/views/supportdashboard/pages/OrderInvoice_update'),
            },
            {
                name: 'Shop Report',
                path: 'pages/shopreport',
                component: () =>
                    import('@/views/supportdashboard/pages/ShopReport'),
            },
            {
                name: 'Rating-Review',
                path: 'pages/review',
                component: () =>
                    import('@/views/supportdashboard/pages/RatingReview.vue'),
            },
            {
                name: 'Analytics',
                path: 'pages/analytics',
                component: () =>
                    import('@/views/supportdashboard/pages/Analytics'),
            },
            {
                name: 'Product Details',
                path: 'pages/productdetails',
                component: () =>
                    import('@/views/supportdashboard/pages/ProductDetails'),
            },
            {
                name: 'Group Products',
                path: 'pages/group-products',
                component: () =>
                    import('@/views/supportdashboard/pages/GroupProducts'),
            },
            {
                name: 'Edit Product Image Support',
                path: 'pages/edit-product-image',
                component: () =>
                    import('@/views/supportdashboard/pages/EditProductImage'),
            },
            {
                name: 'Invoice',
                path: 'pages/invoice',
                component: () =>
                    import('@/views/supportdashboard/pages/Invoice'),
            },
            {
                name: 'Order Invoice',
                path: 'pages/orderinvoice',
                component: () =>
                    import('@/views/supportdashboard/pages/OrderInvoice'),
            },
            {
                name: 'Mother Invoice',
                path: 'pages/motherinvoice',
                component: () =>
                    import('@/views/supportdashboard/pages/MotherInvoice'),
            },
            {
                name: 'Mother Invoice',
                path: 'pages/motherinvoice_updated',
                component: () =>
                    import('@/views/supportdashboard/pages/MotherInvoiceUpdated'),
            },
            {
                name: 'Confirm Product',
                path: 'pages/confirmproduct',
                component: () =>
                    import('@/views/supportdashboard/pages/ConfirmProduct'),
            },
            {
                name: 'Product',
                path: 'pages/product',
                component: () =>
                    import('@/views/supportdashboard/pages/Product'),
            },
            {
                name: 'Group Product',
                path: 'pages/groupproduct',
                component: () =>
                    import('@/views/supportdashboard/pages/GroupProduct'),
            },
            {
                name: 'Add Product',
                path: 'pages/addproduct',
                component: () =>
                    import('@/views/supportdashboard/pages/AddProduct'),
            },
            {
                name: 'Advertisement',
                path: 'pages/advertisement',
                component: () =>
                    import('@/views/supportdashboard/pages/Advertisement'),
            },

            {
                name: 'Add Group Product',
                path: 'pages/addgroupproduct',
                component: () =>
                    import('@/views/supportdashboard/pages/AddGroupProduct'),
            },
            {
                name: 'Contract Us',
                path: 'pages/contractus',
                component: () =>
                    import('@/views/supportdashboard/pages/ContractUs'),
            },
            {
                name: 'FAQ',
                path: 'pages/faq',
                component: () =>
                    import('@/views/supportdashboard/pages/FAQ'),
            },
            {
                name: 'Brand',
                path: 'pages/brand',
                component: () =>
                    import('@/views/supportdashboard/pages/Brand'),
            },
            {
                name: 'Report',
                path: 'pages/report',
                component: () =>
                    import('@/views/supportdashboard/pages/SharedProduct'),
            },

            {
                name: 'Mothersite Product Report',
                path: 'pages/mothersite-report',
                component: () =>
                    import('@/views/supportdashboard/pages/MothersiteProductReport'),
            },
            // {
            //     name: 'Report',
            //     path: 'pages/report',
            //     component: () =>
            //         import ('@/views/supportdashboard/pages/MotherSiteSharedProduct'),
            // },

            {
                name: 'Warehouse',
                path: 'pages/warehouse',
                component: () =>
                    import('@/views/supportdashboard/pages/Warehouse'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/warehousedetails',
                component: () =>
                    import('@/views/supportdashboard/pages/WarehouseDetails'),
            },
            {
                name: 'Shop Details',
                path: 'pages/shopdetails',
                component: () =>
                    import('@/views/supportdashboard/pages/ShopDetails'),
            },
            {
                name: 'Customer Invoice',
                path: 'pages/cuscomerinvoice',
                component: () =>
                    import('@/views/supportdashboard/pages/CustomerInvoice'),
            },

            {
                name: 'Notes',
                path: 'pages/notes',
                component: () =>
                    import('@/views/supportdashboard/pages/CRMNotes'),
            },
            {
                name: 'Order',
                path: 'pages/order',
                component: () =>
                    import('@/views/supportdashboard/pages/Order'),
            },
            {
                name: 'Merchant',
                path: 'pages/merchantreport',
                component: () =>
                    import('@/views/supportdashboard/pages/MerchentReport'),
            },
            {
                name: 'Daily Product Report',
                path: 'pages/daily_report',
                component: () =>
                    import('@/views/supportdashboard/pages/DailyReport'),
            },
            {
                name: 'Daily Product Report',
                path: 'pages/stock_report',
                component: () =>
                    import('@/views/supportdashboard/pages/StockReport'),
            },
            {
                name: 'New Invoice',
                path: 'pages/new_invoice',
                component: () =>
                    import('@/views/supportdashboard/pages/NewOrderInvoice'),
            },
            {
                name: 'New Invoice',
                path: 'pages/new_invoiceUpdated',
                component: () =>
                    import('@/views/supportdashboard/pages/NewOrderInvoiceAzizSir'),
            },
            {
                name: 'Merchant Details',
                path: 'pages/merchantdetails',
                component: () =>
                    import('@/views/supportdashboard/pages/MerchentDetails'),
            },
            {
                name: 'Sales',
                path: 'pages/sales',
                component: () =>
                    import('@/views/supportdashboard/pages/Sales'),
            },
            // {
            //     name: 'Pending Product',
            //     path: 'pages/pendingproducts',
            //     component: () =>
            //         import('@/views/supportdashboard/pages/SellerProduct'),
            // },

            {
                name: 'Pending Product',
                path: 'pages/pendingproducts',
                component: () =>
                    import('@/views/supportdashboard/pages/MerchantProduct'),
            },

            {
                name: 'Mother Product',
                path: 'pages/mother-products',
                component: () =>
                    import('@/views/supportdashboard/pages/MotherSiteSharedProduct'),
            },
            {
                name: 'UnShared Product',
                path: 'pages/unshared-products',
                component: () =>
                    import('@/views/supportdashboard/pages/UnsharedProduct'),
            },
            {
                name: 'Unpublished Product',
                path: 'pages/unpublished-products',
                component: () =>
                    import('@/views/supportdashboard/pages/UnpublishedProduct'),
            },
            {
                name: 'specification',
                path: 'pages/specification',
                component: () =>
                    import('@/views/supportdashboard/pages/Specification'),
            },
            {
                name: 'Specification Barcode',
                path: 'pages/specification_barcode',
                component: () =>
                    import('@/views/supportdashboard/pages/SpecificationBarcode'),
            },
            {
                name: 'addproce',
                path: 'pages/add-price',
                component: () =>
                    import('@/views/supportdashboard/pages/AddPrice'),
            },
            {
                name: 'viewcomments',
                path: 'pages/viewcomments',
                component: () =>
                    import('@/views/supportdashboard/pages/ViewComments'),
            },
            {
                name: 'groupspecification',
                path: 'pages/groupspecification',
                component: () =>
                    import('@/views/supportdashboard/pages/GroupSpecification'),
            },
            // {
            //   name: 'Order Details',
            //   path: 'pages/orderdetails',
            //   component: () => import('@/views/supportdashboard/pages/OrderDetail'),
            // },

            {
                name: 'Admin Invoices',
                path: 'pages/invoices',
                component: () =>
                    import('@/views/supportdashboard/pages/AdminInvoices'),
            },

            {
                name: 'Admin Order Invoice Approval',
                path: 'pages/approveinvoice',
                component: () =>
                    import('@/views/supportdashboard/pages/InvoiceApproval'),
            },


            {
                name: 'Banner',
                path: 'pages/banner',
                component: () =>
                    import('@/views/supportdashboard/pages/Banner'),
            },

            {
                name: 'Add Banner',
                path: 'pages/addbanner',
                component: () =>
                    import('@/views/supportdashboard/pages/AddBanner'),
            },

            {
                name: 'Delivery',
                path: 'pages/api/delivery',
                component: () =>
                    import('@/views/supportdashboard/pages/api/Delivery'),
            },
            {
                name: 'CRM',
                path: 'pages/api/crm',
                component: () =>
                    import('@/views/supportdashboard/pages/api/CRM'),
            },
            {
                name: 'Email Configure',
                path: 'pages/emailconfig',
                component: () =>
                    import('@/views/supportdashboard/pages/EmailConfigure'),
            },
            {
                name: 'Supports',
                path: 'pages/supports',
                component: () =>
                    import('@/views/supportdashboard/pages/Supports'),
            },
            {
                name: 'POS Report',
                path: 'pages/pos_report',
                component: () =>
                    import('@/views/supportdashboard/pages/POSReport'),
            },
            {
                name: 'POS Invoice',
                path: 'pages/pos_invoice',
                component: () =>
                    import('@/views/supportdashboard/pages/POSInvoice'),
            },
            {
                name: 'POS Invoice',
                path: 'pages/pos_invoice_updated',
                component: () =>
                    import('@/views/supportdashboard/pages/POSInvoice_Aziz_Sir'),
            },
            {
                name: 'Find Invoice',
                path: 'pages/find-invoice',
                component: () =>
                    import('@/views/supportdashboard/pages/FindInvoice'),
            },

            {
                name: 'CRM Support Chat',
                path: 'pages/chat',
                component: () =>
                    import('@/views/supportdashboard/pages/CRMChat'),
            },
            {
                name: 'Setting',
                path: 'pages/setting',
                component: () =>
                    import('@/views/supportdashboard/pages/Setting'),
            },
            {
                name: 'Notifications',
                path: 'components/notifications',
                component: () =>
                    import('@/views/supportdashboard/component/Notifications'),
            },

            {
                name: 'Icons',
                path: 'components/icons',
                component: () =>
                    import('@/views/supportdashboard/component/Icons'),
            },
            // Tables
            {
                name: 'Regular Tables',
                path: 'tables/regular-tables',
                component: () =>
                    import('@/views/supportdashboard/tables/RegularTables'),
            },
            // Maps
            {
                name: 'Google Maps',
                path: 'maps/google-maps',
                component: () =>
                    import('@/views/supportdashboard/maps/GoogleMaps'),
            },
            // Upgrade
            {
                name: 'Upgrade',
                path: 'upgrade',
                component: () =>
                    import('@/views/supportdashboard/Upgrade'),
            },

            {
                name: 'Coupon',
                path: 'pages/coupon',
                component: () =>
                    import('@/views/supportdashboard/pages/AddCoupon'),
            },

            //all users
            {
                name: 'user',
                path: 'pages/alluser',
                component: () =>
                    import('@/views/supportdashboard/pages/Users'),
            },

            //all merchant list
            {
                name: 'merchants',
                path: 'pages/merchants',
                component: () =>
                    import('@/views/supportdashboard/pages/Merchants'),
            },


            // Expense related routes
            {
                name: 'AllExpenses',
                path: 'pages/expenses',
                component: () =>
                    import('@/views/supportdashboard/pages/expenses/AllExpenses'),
            },
            {
                name: 'CreateVoucher',
                path: 'pages/create-voucher',
                component: () =>
                    import('@/views/supportdashboard/pages/expenses/CreateVoucher'),
            },
            {
                name: 'ShowExpenses',
                path: 'pages/all-expenses',
                component: () =>
                    import('@/views/supportdashboard/pages/expenses/ShowExpenses'),
            },
            {
                name: 'ExpenseReport',
                path: 'pages/expense-report',
                component: () =>
                    import('@/views/supportdashboard/pages/expenses/ExpenseReport'),
            },
            {
                name: 'ExpenseOrderDetails',
                path: 'pages/expense-report/order',
                component: () =>
                    import('@/views/supportdashboard/pages/expenses/OrderDetails'),
            },
            {
                name: 'ExpenseDetails',
                path: 'pages/expense-report/expense',
                component: () =>
                    import('@/views/supportdashboard/pages/expenses/ExpenseDetails'),
            },
        ],
    },

    // {
    //     path: '/sellerdashboard',
    //     component: () =>
    //         import('@/views/sellerdashboard/sellerIndex'),
    //     meta: {
    //         sellerAuth: true,
    //     },
    //     children: [
    //         // Dashboard
    //         {
    //             name: 'SellerDashboard',
    //             path: '',
    //             component: () =>
    //                 import('@/views/sellerdashboard/SellerDashboard'),
    //         },
    //         // Pages
    //         {
    //             name: 'Rating-Review',
    //             path: 'pages/review',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/RatingReview.vue'),
    //         },

    //         {
    //             name: 'Product Details',
    //             path: 'pages/productdetails',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/ProductDetails'),
    //         },

    //         {
    //             name: 'Seller Invoices',
    //             path: 'pages/invoices',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/SellerInvoices'),
    //         },

    //         {
    //             name: 'Seller Order Invoice',
    //             path: 'pages/orderinvoice',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/OrderInvoice'),
    //         },


    //         {
    //             name: 'Product',
    //             path: 'pages/product',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/Product'),
    //         },


    //         {
    //             name: 'Group Product',
    //             path: 'pages/groupproduct',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/GroupProduct'),
    //         },
    //         {
    //             name: 'Add Product',
    //             path: 'pages/addproduct',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/AddProduct'),
    //         },
    //         {
    //             name: 'Support Ticket',
    //             path: 'pages/sellerticket',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/SellerTicket'),
    //         },
    //         {
    //             name: 'cancel product',
    //             path: 'pages/cancel',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/Products/ProductCancel'),
    //         },
    //         {
    //             name: 'approvedproduct',
    //             path: 'pages/approvedproduct',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/Products/ProductApproved'),
    //         },
    //         {
    //             name: 'pendingproduct',
    //             path: 'pages/pendingproduct',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/Products/ProductPending'),
    //         },

    //         {
    //             name: 'Add Group Product',
    //             path: 'pages/addgroupproduct',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/AddGroupProduct'),
    //         },

    //         {
    //             name: 'specification',
    //             path: 'pages/specification',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/Specification'),
    //         },
    //         {
    //             name: 'viewcomments',
    //             path: 'pages/viewcomments',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/ViewComments'),
    //         },

    //         {
    //             name: 'groupspecification',
    //             path: 'pages/groupspecification',
    //             component: () =>
    //                 import('@/views/sellerdashboard/pages/GroupSpecification'),
    //         },




    //         // {
    //         //   name: 'Order Details',
    //         //   path: 'pages/orderdetails',
    //         //   component: () => import('@/views/dashboard/pages/OrderDetail'),
    //         // },


    //         {
    //             name: 'Icons',
    //             path: 'components/icons',
    //             component: () =>
    //                 import('@/views/sellerdashboard/component/Icons'),
    //         },
    //         // Tables
    //         {
    //             name: 'Regular Tables',
    //             path: 'tables/regular-tables',
    //             component: () =>
    //                 import('@/views/sellerdashboard/tables/RegularTables'),
    //         },

    //     ],
    // },
    {
        path: '/deliverydashboard',
        component: () =>
            import('@/views/deliverydashboard/deliveryIndex'),
        meta: {
            deliveryAuth: true,
        },
        children: [
            // Dashboard
            {
                name: 'Delivery Dashboard',
                path: '',
                component: () =>
                    import('@/views/deliverydashboard/DeliveryDashboard'),
            },
            {
                name: 'Delivery Details',
                path: '/deliverydashboard/pages/deliverydetails/:id',
                component: () =>
                    import('@/views/deliverydashboard/pages/DeliveryDetails'),
            },
        ],
    },
    {
        path: '/sellerdeliverydashboard',
        component: () =>
            import('@/views/sellerdeliverydashboard/deliveryIndex'),
        meta: {
            deliveryAuth: true,
        },
        children: [
            // Dashboard
            {
                name: 'Delivery Dashboard',
                path: '',
                component: () =>
                    import('@/views/sellerdeliverydashboard/DeliveryDashboard'),
            },
            {
                name: 'Delivery Details',
                path: '/sellerdeliverydashboard/pages/deliverydetails/:id',
                component: () =>
                    import('@/views/sellerdeliverydashboard/pages/DeliveryDetails'),
            },
        ],
    },

    {
        path: '/managerdashboard',
        component: () =>
            import('@/views/managerdashboard/managerIndex'),
        meta: {
            managerAuth: true,
        },
        children: [
            // Dashboard
            {
                name: 'Manager Dashboard',
                path: '',
                component: () =>
                    import('@/views/managerdashboard/Managerdashboard'),
            },
            // Pages
            {
                name: 'Warehouse Approval Request',
                path: 'pages/warehouse_approval_request',
                component: () =>
                    import('@/views/managerdashboard/pages/WarehouseApprovalRequest'),
            },
            {
                name: 'Shop Approval Request',
                path: 'pages/Shop_approval_request',
                component: () =>
                    import('@/views/managerdashboard/pages/ShopApprovalRequest'),
            },
            {
                name: 'Transfer Product',
                path: 'pages/product_tranfer',
                component: () =>
                    import('@/views/managerdashboard/pages/Product_Transaction'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/warehouseproduct_tranfer',
                component: () =>
                    import('@/views/managerdashboard/pages/WarehouseTransactioDetails'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/shop_tranfer',
                component: () =>
                    import('@/views/managerdashboard/pages/ShopTransactioDetails'),
            },
            {
                name: 'Warehouse Tranfer Report',
                path: 'pages/transfer_Warehouse',
                component: () =>
                    import('@/views/managerdashboard/pages/WarehouseTransferReport'),
            },
            {
                name: 'Group Product',
                path: 'pages/group-products/',
                component: () =>
                    import('@/views/managerdashboard/pages/GroupProductsIndex'),
            },
            {
                name: 'Group Product',
                path: 'pages/group-products/warehouse/chalans',
                component: () =>
                    import('@/views/managerdashboard/pages/WarehouseGroupProductsIndex'),
            },
            {
                name: 'Group Product',
                path: 'pages/group-products/shop/chalans',
                component: () =>
                    import('@/views/managerdashboard/pages/ShopGroupProductsIndex'),
            },
            {
                name: 'Group Product Chalan Approval',
                path: 'pages/group-products/warehouse/chalan_approval',
                component: () =>
                    import('@/views/managerdashboard/pages/ManagerGroupProductChalanApproval'),
            },
            {
                name: 'Group Product Chalan Approval Shop',
                path: 'pages/group-products/shop/chalan_approval',
                component: () =>
                    import('@/views/managerdashboard/pages/ManagerGroupProductChalanApprovalShop'),
            },
            {
                name: 'Hub Transfer',
                path: 'pages/hub-transfer/',
                component: () =>
                    import('@/views/managerdashboard/pages/HubTransferIndex'),
            },
            {
                name: 'Hub Transfer Shop',
                path: 'pages/hub-transfer/shop',
                component: () =>
                    import('@/views/managerdashboard/pages/HubTransferShopIndex'),
            },
            {
                name: 'Hub Transfer Warehouse',
                path: 'pages/hub-transfer/warehouse',
                component: () =>
                    import('@/views/managerdashboard/pages/HubTransferWarehouseIndex'),
            },
            {
                name: 'Hub Transfer Approval',
                path: 'pages/hub-transfer/approval',
                component: () =>
                    import('@/views/managerdashboard/pages/HubTransferApproval'),
            },
            {
                name: 'Hub Transfer Approval Sent',
                path: 'pages/hub-transfer/approval/sent',
                component: () =>
                    import('@/views/managerdashboard/pages/HubTransferApprovalSent'),
            },
            {
                name: 'User',
                path: 'pages/users',
                component: () =>
                    import('@/views/managerdashboard/pages/users/UserIndex.vue'),
            },
            {
                name: 'Order',
                path: 'pages/orders',
                component: () =>
                    import('@/views/managerdashboard/pages/orders/OrderIndex.vue'),
            },
            {
                name: 'Order List',
                path: 'pages/orders/list',
                component: () =>
                    import('@/views/managerdashboard/pages/orders/OrderList.vue'),
            },
            {
                name: 'Order List',
                path: 'pages/orders/approval',
                component: () =>
                    import('@/views/managerdashboard/pages/orders/OrderApproval.vue'),
            },
            {
                name: 'Order Invoice',
                path: 'pages/orders/invoice',
                component: () =>
                    import('@/views/managerdashboard/pages/orders/OrderInvoice.vue'),
            },
            {
                name: 'Transfer Invoice',
                path: 'pages/transfer_invoice',
                component: () =>
                    import('@/views/managerdashboard/pages/TransferInvoice'),
            },
            {
                name: 'Own Transfer Invoice',
                path: 'pages/own_transfer_invoice',
                component: () =>
                    import('@/views/managerdashboard/pages/OwnTransferInvoice'),
            },

            {
                name: 'Chalan Approval',
                path: 'pages/chalan_approval',
                component: () =>
                    import('@/views/managerdashboard/pages/ManagerChalanApproval'),
            },

            {
                name: 'Chalan Approval Shop',
                path: 'pages/chalan_approval/shop',
                component: () =>
                    import('@/views/managerdashboard/pages/ManagerChalanApprovalShop'),
            },


            {
                name: 'Chalan Approval',
                path: 'pages/chalan_report',
                component: () =>
                    import('@/views/managerdashboard/pages/ManagerChalanRport'),
            },

            // {
            //   name: 'Order Details',
            //   path: 'pages/orderdetails',
            //   component: () => import('@/views/dashboard/pages/OrderDetail'),
            // },


            {
                name: 'Icons',
                path: 'components/icons',
                component: () =>
                    import('@/views/sellerdashboard/component/Icons'),
            },
            // Tables
            {
                name: 'Regular Tables',
                path: 'tables/regular-tables',
                component: () =>
                    import('@/views/sellerdashboard/tables/RegularTables'),
            },

            {
                name: 'Transfer',
                path: 'pages/transfer',
                component: () =>
                    import('@/views/managerdashboard/pages/transfer_request/TransferIndex'),
            },
            {
                name: 'Transfer Warehouse',
                path: 'pages/transfer/warehouse',
                component: () =>
                    import('@/views/managerdashboard/pages/transfer_request/TransferWarehouse'),
            },

        ],
    },

    {
        path: '/merchant-managerdashboard',
        component: () =>
            import('@/views/merchant-managerdashboard/managerIndex'),
        meta: {
            merchantManagerAuth: true,
        },
        children: [
            // Dashboard
            {
                name: 'Manager Dashboard',
                path: '',
                component: () =>
                    import('@/views/merchant-managerdashboard/Managerdashboard'),
            },
            // Pages
            {
                name: 'Warehouse Approval Request',
                path: 'pages/warehouse_approval_request',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/WarehouseApprovalRequest'),
            },
            {
                name: 'Shop Approval Request',
                path: 'pages/Shop_approval_request',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ShopApprovalRequest'),
            },
            {
                name: 'Transfer Product',
                path: 'pages/product_tranfer',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/Product_Transaction'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/warehouseproduct_tranfer',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/WarehouseTransactioDetails'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/shop_tranfer',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ShopTransactioDetails'),
            },
            {
                name: 'Warehouse Tranfer Report',
                path: 'pages/transfer_Warehouse',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/WarehouseTransferReport'),
            },
            {
                name: 'Group Product',
                path: 'pages/group-products/',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/GroupProductsIndex'),
            },
            {
                name: 'Group Product',
                path: 'pages/group-products/warehouse/chalans',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/WarehouseGroupProductsIndex'),
            },
            {
                name: 'Group Product',
                path: 'pages/group-products/shop/chalans',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ShopGroupProductsIndex'),
            },
            {
                name: 'Group Product Chalan Approval',
                path: 'pages/group-products/warehouse/chalan_approval',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ManagerGroupProductChalanApproval'),
            },
            {
                name: 'Group Product Chalan Approval Shop',
                path: 'pages/group-products/shop/chalan_approval',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ManagerGroupProductChalanApprovalShop'),
            },
            {
                name: 'Hub Transfer',
                path: 'pages/hub-transfer/',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/HubTransferIndex'),
            },
            {
                name: 'Hub Transfer Shop',
                path: 'pages/hub-transfer/shop',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/HubTransferShopIndex'),
            },
            {
                name: 'Hub Transfer Warehouse',
                path: 'pages/hub-transfer/warehouse',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/HubTransferWarehouseIndex'),
            },
            {
                name: 'Hub Transfer Approval',
                path: 'pages/hub-transfer/approval',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/HubTransferApproval'),
            },
            {
                name: 'Hub Transfer Approval Sent',
                path: 'pages/hub-transfer/approval/sent',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/HubTransferApprovalSent'),
            },
            {
                name: 'User',
                path: 'pages/users',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/users/UserIndex.vue'),
            },
            {
                name: 'Order',
                path: 'pages/orders',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/orders/OrderIndex.vue'),
            },
            {
                name: 'Order List',
                path: 'pages/orders/list',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/orders/OrderList.vue'),
            },
            {
                name: 'Order List',
                path: 'pages/orders/approval',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/orders/OrderApproval.vue'),
            },
            {
                name: 'Order Invoice',
                path: 'pages/orders/invoice',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/orders/OrderInvoice.vue'),
            },
            {
                name: 'Transfer Invoice',
                path: 'pages/transfer_invoice',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/TransferInvoice'),
            },
            {
                name: 'Own Transfer Invoice',
                path: 'pages/own_transfer_invoice',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/OwnTransferInvoice'),
            },
            {
                name: 'Chalan Approval',
                path: 'pages/chalan_approval',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ManagerChalanApproval'),
            },
            {
                name: 'Chalan Approval Shop',
                path: 'pages/chalan_approval/shop',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ManagerChalanApprovalShop'),
            },
            {
                name: 'Chalan Approval',
                path: 'pages/chalan_report',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/ManagerChalanRport'),
            },
            {
                name: 'Icons',
                path: 'components/icons',
                component: () =>
                    import('@/views/sellerdashboard/component/Icons'),
            },
            {
                name: 'Transfer',
                path: 'pages/transfer',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/transfer_request/TransferIndex'),
            },
            {
                name: 'Transfer Warehouse',
                path: 'pages/transfer/warehouse',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/transfer_request/TransferWarehouse'),
            },

            {
                name: 'Transfer Shop',
                path: 'pages/transfer/shop',
                component: () =>
                    import('@/views/merchant-managerdashboard/pages/transfer_request/TransferShop'),
            },

        ],
    },

    {
        path: '/merchantdashboard',
        component: () =>
            import('@/views/merchantdashboard/merchantIndex'),
        meta: {
            merchantAuth: true,
        },
        children: [
            // Dashboard
            {
                name: 'Dashboard',
                path: '',
                component: () =>
                    import('@/views/merchantdashboard/MerchantDashboard'),
            },
            // Pages
            {
                name: 'Product Details',
                path: 'pages/productdetails',
                component: () =>
                    import('@/views/merchantdashboard/pages/ProductDetails'),
            },
            {
                name: 'specification',
                path: 'pages/specification',
                component: () =>
                    import('@/views/merchantdashboard/pages/Specification'),
            },
            {
                name: 'User Profile',
                path: 'pages/user',
                component: () =>
                    import('@/views/merchantdashboard/pages/UserProfile'),
            },
            {
                name: 'Warehouse Approve',
                path: 'pages/warehouseapproval',
                component: () =>
                    import('@/views/merchantdashboard/pages/warehouseApproveProduct'),
            },
            {
                name: 'Manager User Details',
                path: 'pages/manageruserdetails',
                component: () =>
                    import('@/views/merchantdashboard/pages/ManagerDetails'),
            },
            // {
            //     name: 'Block Unblock',
            //     path: 'pages/block',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/BlockUnblock'),
            // },
            // {
            //     name: 'Purchase Invoice',
            //     path: 'pages/mother-invoices',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MotherSiteOrderInvoice'),
            // },

            // {
            //     name: 'Flash Sale',
            //     path: 'pages/flash_sale',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/FlashSale'),
            // },
            {
                name: 'Payment',
                path: 'pages/payment',
                component: () =>
                    import('@/views/merchantdashboard/pages/payment/index'),
            },
            // {
            //     name: 'Purchase Invoice',
            //     path: 'pages/mother-purchase-invoices',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MotherSiteOrderPurchaseInvoice'),
            // },
            {
                name: 'Sales',
                path: 'pages/sales',
                component: () =>
                    import('@/views/merchantdashboard/pages/Sales'),
            },
            {
                name: 'Sales Report',
                path: 'pages/salesreport',
                component: () =>
                    import('@/views/merchantdashboard/pages/SalesReport'),
            },
            {
                name: 'Warehouse Report',
                path: 'pages/warehousereport',
                component: () =>
                    import('@/views/merchantdashboard/pages/WarehouseReport'),
            },
            // {
            //     name: 'Money Report',
            //     path: 'pages/moneypdf',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MoneyRecipt'),
            // },
            {
                name: 'POS Management',
                path: 'pages/pos',
                component: () =>
                    import('@/views/merchantdashboard/pages/POS'),
            },
            {
                name: 'View Chalan',
                path: 'pages/admin_chalan',
                component: () =>
                    import('@/views/merchantdashboard/pages/AdminChalan'),
            },
            {
                name: 'POS Order',
                path: 'pages/pos_order',
                component: () =>
                    import('@/views/merchantdashboard/pages/POS_Order'),
            },
            // {
            //     name: 'Imported Product',
            //     path: 'pages/imported_product',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/ImportedProduct'),
            // },
            // {
            //     name: 'Import Specification',
            //     path: 'pages/import_specification',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/ImportSpecification'),
            // },
            {
                name: 'POS Details',
                path: 'pages/posdetails',
                component: () =>
                    import('@/views/merchantdashboard/pages/POSDetails'),
            },
            {
                name: 'Product Order Invoice',
                path: 'pages/orderinvoice_updated',
                component: () =>
                    import('@/views/merchantdashboard/pages/OrderInvoice_update'),
            },
            {
                name: 'Shop Report',
                path: 'pages/shopreport',
                component: () =>
                    import('@/views/merchantdashboard/pages/ShopReport'),
            },
            {
                name: 'Rating-Review',
                path: 'pages/review',
                component: () =>
                    import('@/views/merchantdashboard/pages/RatingReview.vue'),
            },
            // {
            //     name: 'Analytics',
            //     path: 'pages/analytics',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/Analytics'),
            // },

            // {
            //     name: 'Group Products',
            //     path: 'pages/group-products',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/GroupProducts'),
            // },
            {
                name: 'Edit Product Image Merchant',
                path: 'pages/edit-product-image',
                component: () =>
                    import('@/views/merchantdashboard/pages/EditProductImage'),
            },
            {
                name: 'Invoice',
                path: 'pages/invoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/Invoice'),
            },
            {
                name: 'Order Invoice',
                path: 'pages/orderinvoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/OrderInvoice'),
            },
            {
                name: 'Mother Invoice',
                path: 'pages/motherinvoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/MotherInvoice'),
            },
            {
                name: 'Mother Invoice',
                path: 'pages/motherinvoice_updated',
                component: () =>
                    import('@/views/merchantdashboard/pages/MotherInvoiceUpdated'),
            },
            {
                name: 'Confirm Product',
                path: 'pages/confirmproduct',
                component: () =>
                    import('@/views/merchantdashboard/pages/ConfirmProduct'),
            },
            {
                name: 'Product',
                path: 'pages/product',
                component: () =>
                    import('@/views/merchantdashboard/pages/Product'),
            },
            // {
            //     name: 'Group Product',
            //     path: 'pages/groupproduct',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/GroupProduct'),
            // },
            {
                name: 'Add Product',
                path: 'pages/addproduct',
                component: () =>
                    import('@/views/merchantdashboard/pages/AddProduct'),
            },
            {
                name: 'Advertisement',
                path: 'pages/advertisement',
                component: () =>
                    import('@/views/merchantdashboard/pages/Advertisement'),
            },

            // {
            //     name: 'Add Group Product',
            //     path: 'pages/addgroupproduct',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/AddGroupProduct'),
            // },
            // {
            //     name: 'Company Information',
            //     path: 'pages/companyinfo',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/CompanyInfo'),
            // },
            // {
            //     name: 'Company Information',
            //     path: 'pages/addcompanyinfo',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/AddCompanyInfo'),
            // },
            // {
            //     name: 'Contract Us',
            //     path: 'pages/contractus',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/ContractUs'),
            // },
            // {
            //     name: 'FAQ',
            //     path: 'pages/faq',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/FAQ'),
            // },
            // {
            //     name: 'Brand',
            //     path: 'pages/brand',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/Brand'),
            // },
            {
                name: 'Report',
                path: 'pages/report',
                component: () =>
                    import('@/views/merchantdashboard/pages/SharedProduct'),
            },

            {
                name: 'Merchant Commission',
                path: 'pages/report/commission',
                component: () =>
                    import('@/views/merchantdashboard/pages/commission/index'),
            },

            // {
            //     name: 'Mothersite Product Report',
            //     path: 'pages/mothersite-report',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MothersiteProductReport'),
            // },
            // {
            //     name: 'Report',
            //     path: 'pages/report',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MotherSiteSharedProduct'),
            // },
            // {
            //     name: 'Approve Seller',
            //     path: 'pages/approve-products',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/ApproveSellerProduct'),
            // },
            {
                name: 'Warehouse',
                path: 'pages/warehouse',
                component: () =>
                    import('@/views/merchantdashboard/pages/Warehouse'),
            },
            {
                name: 'Warehouse Details',
                path: 'pages/warehousedetails',
                component: () =>
                    import('@/views/merchantdashboard/pages/WarehouseDetails'),
            },
            {
                name: 'Shop Details',
                path: 'pages/shopdetails',
                component: () =>
                    import('@/views/merchantdashboard/pages/ShopDetails'),
            },
            {
                name: 'Customer Invoice',
                path: 'pages/cuscomerinvoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/CustomerInvoice'),
            },
            // {
            //     name: 'CRM Chat',
            //     path: 'pages/chat',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/CRMChat'),
            // },
            // {
            //     name: 'Notes',
            //     path: 'pages/notes',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/CRMNotes'),
            // },
            {
                name: 'Order',
                path: 'pages/order',
                component: () =>
                    import('@/views/merchantdashboard/pages/Order'),
            },
            // {
            //     name: 'Merchant',
            //     path: 'pages/merchantreport',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MerchentReport'),
            // },
            {
                name: 'Daily Product Report',
                path: 'pages/daily_report',
                component: () =>
                    import('@/views/merchantdashboard/pages/DailyReport'),
            },
            {
                name: 'Daily Product Report',
                path: 'pages/stock_report',
                component: () =>
                    import('@/views/merchantdashboard/pages/StockReport'),
            },
            {
                name: 'New Invoice',
                path: 'pages/new_invoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/NewOrderInvoice'),
            },
            {
                name: 'New Invoice',
                path: 'pages/new_invoiceUpdated',
                component: () =>
                    import('@/views/merchantdashboard/pages/NewOrderInvoiceAzizSir'),
            },
            // {
            //     name: 'Merchant Details',
            //     path: 'pages/merchantdetails',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MerchentDetails'),
            // },
            {
                name: 'Sales',
                path: 'pages/sales',
                component: () =>
                    import('@/views/merchantdashboard/pages/Sales'),
            },
            // {
            //     name: 'Pending Product',
            //     path: 'pages/pendingproducts',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/SellerProduct'),
            // },

            // {
            //     name: 'Mother Product',
            //     path: 'pages/mother-products',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/MotherSiteSharedProduct'),
            // },
            // {
            //     name: 'UnSahred Product',
            //     path: 'pages/unshared-products',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/UnsharedProduct'),
            // },
            // {
            //     name: 'Unpublished Product',
            //     path: 'pages/unpublished-products',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/UnpublishedProduct'),
            // },

            {
                name: 'Specification Barcode',
                path: 'pages/specification_barcode',
                component: () =>
                    import('@/views/merchantdashboard/pages/SpecificationBarcode'),
            },
            {
                name: 'addproce',
                path: 'pages/add-price',
                component: () =>
                    import('@/views/merchantdashboard/pages/AddPrice'),
            },
            {
                name: 'viewcomments',
                path: 'pages/viewcomments',
                component: () =>
                    import('@/views/merchantdashboard/pages/ViewComments'),
            },
            // {
            //     name: 'groupspecification',
            //     path: 'pages/groupspecification',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/GroupSpecification'),
            // },
            // {
            //   name: 'Order Details',
            //   path: 'pages/orderdetails',
            //   component: () => import('@/views/dashboard/pages/OrderDetail'),
            // },

            {
                name: 'Admin Invoices',
                path: 'pages/invoices',
                component: () =>
                    import('@/views/merchantdashboard/pages/AdminInvoices'),
            },

            {
                name: 'Admin Order Invoice Approval',
                path: 'pages/approveinvoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/InvoiceApproval'),
            },


            {
                name: 'Banner',
                path: 'pages/banner',
                component: () =>
                    import('@/views/merchantdashboard/pages/Banner'),
            },

            {
                name: 'Add Banner',
                path: 'pages/addbanner',
                component: () =>
                    import('@/views/merchantdashboard/pages/AddBanner'),
            },

            // {
            //     name: 'Delivery',
            //     path: 'pages/api/delivery',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/api/Delivery'),
            // },
            // {
            //     name: 'CRM',
            //     path: 'pages/api/crm',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/api/CRM'),
            // },
            // {
            //     name: 'Email Configure',
            //     path: 'pages/emailconfig',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/EmailConfigure'),
            // },
            // {
            //     name: 'Supports',
            //     path: 'pages/supports',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/Supports'),
            // },
            {
                name: 'POS Report',
                path: 'pages/pos_report',
                component: () =>
                    import('@/views/merchantdashboard/pages/POSReport'),
            },
            {
                name: 'POS Invoice',
                path: 'pages/pos_invoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/POSInvoice'),
            },
            {
                name: 'POS Invoice',
                path: 'pages/pos_invoice_updated',
                component: () =>
                    import('@/views/merchantdashboard/pages/POSInvoice_Aziz_Sir'),
            },
            {
                name: 'Find Invoice',
                path: 'pages/find-invoice',
                component: () =>
                    import('@/views/merchantdashboard/pages/FindInvoice'),
            },
            {
                name: 'Setting',
                path: 'pages/setting',
                component: () =>
                    import('@/views/merchantdashboard/pages/Setting'),
            },
            // {
            //     name: 'Notifications',
            //     path: 'components/notifications',
            //     component: () =>
            //         import ('@/views/merchantdashboard/component/Notifications'),
            // },

            {
                name: 'Icons',
                path: 'components/icons',
                component: () =>
                    import('@/views/merchantdashboard/component/Icons'),
            },
            // Tables
            // {
            //     name: 'Regular Tables',
            //     path: 'tables/regular-tables',
            //     component: () =>
            //         import ('@/views/merchantdashboard/tables/RegularTables'),
            // },
            // Maps
            // {
            //     name: 'Google Maps',
            //     path: 'maps/google-maps',
            //     component: () =>
            //         import ('@/views/merchantdashboard/maps/GoogleMaps'),
            // },
            // Upgrade
            // {
            //     name: 'Upgrade',
            //     path: 'upgrade',
            //     component: () =>
            //         import ('@/views/merchantdashboard/Upgrade'),
            // },

            // {
            //     name: 'Coupon',
            //     path: 'pages/coupon',
            //     component: () =>
            //         import ('@/views/merchantdashboard/pages/AddCoupon'),
            // },

            //all users
            {
                name: 'user',
                path: 'pages/alluser',
                component: () =>
                    import('@/views/merchantdashboard/pages/Users'),
            },

            {
                name: 'profile',
                path: 'pages/profile',
                component: () =>
                    import('@/views/merchantdashboard/pages/Profile'),
            },


            // Expense related routes
            //     {
            //         name: 'AllExpenses',
            //         path: 'pages/expenses',
            //         component: () =>
            //             import ('@/views/merchantdashboard/pages/expenses/AllExpenses'),
            //     },
            //     {
            //         name: 'CreateVoucher',
            //         path: 'pages/create-voucher',
            //         component: () =>
            //             import ('@/views/merchantdashboard/pages/expenses/CreateVoucher'),
            //     },
            //     {
            //         name: 'ShowExpenses',
            //         path: 'pages/all-expenses',
            //         component: () =>
            //             import ('@/views/merchantdashboard/pages/expenses/ShowExpenses'),
            //     },
            //     {
            //         name: 'ExpenseReport',
            //         path: 'pages/expense-report',
            //         component: () =>
            //             import ('@/views/merchantdashboard/pages/expenses/ExpenseReport'),
            //     },
            //     {
            //         name: 'ExpenseOrderDetails',
            //         path: 'pages/expense-report/order',
            //         component: () =>
            //             import ('@/views/merchantdashboard/pages/expenses/OrderDetails'),
            //     },
            //     {
            //         name: 'ExpenseDetails',
            //         path: 'pages/expense-report/expense',
            //         component: () =>
            //             import ('@/views/merchantdashboard/pages/expenses/ExpenseDetails'),
            //     },
        ],

    },
    ],
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem("user")) {
            next({ name: "login" })
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role.toLowerCase() == 'admin') {
                next()
            } else if (user.role.toLowerCase() == 'manager') {
                next('/managerdashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next('/supportdashboard')
            } else if (user.role.toLowerCase() == 'delivery man') {
                next('/deliverydashboard')
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next('/merchant-managerdashboard')
            } else if (user.role.toLowerCase() == 'seller') {
                next('/merchantdashboard')
            } else {
                next({ name: "login" })
            }
        }
    }
    else if (to.matched.some(record => record.meta.guest)) {
        if (localStorage.getItem("user")) {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role.toLowerCase() == 'admin') {
                next('/dashboard')
            } else if (user.role.toLowerCase() == 'manager') {
                next('/managerdashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next('/supportdashboard')
            } else if (user.role.toLowerCase() == 'delivery man') {
                next('/deliverydashboard')
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next('/merchant-managerdashboard')
            } else if (user.role.toLowerCase() == 'seller') {
                next('/merchantdashboard')
            } else {
                next({ name: "login" })
            }
        }
        else if (!localStorage.getItem("user")) {
            next()
        }
    }
    else if (to.matched.some(record => record.meta.managerAuth)) {
        if (!localStorage.getItem("user")) {
            next({ name: "login" })
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role == "manager") {
                next()
            } else if (user.role.toLowerCase() == 'admin') {
                next('/dashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next('/supportdashboard')
            } else if (user.role.toLowerCase() == 'delivery man') {
                next('/deliverydashboard')
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next('/merchant-managerdashboard')
            } else if (user.role.toLowerCase() == 'seller') {
                next('/merchantdashboard')
            } else {
                next({ name: "login" })
            }
        }

    }
    else if (to.matched.some(record => record.meta.supportAuth)) {
        if (!localStorage.getItem("user")) {
            next({ name: "login" })
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role.toLowerCase() == 'admin') {
                next('/dashboard')
            } else if (user.role.toLowerCase() == 'manager') {
                next('/managerdashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next()
            } else if (user.role.toLowerCase() == 'delivery man') {
                next('/deliverydashboard')
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next('/merchant-managerdashboard')
            } else if (user.role.toLowerCase() == 'seller') {
                next('/merchantdashboard')
            } else {
                next({ name: "login" })
            }
        }

    }
    else if (to.matched.some(record => record.meta.merchantManagerAuth)) {
        if (!localStorage.getItem("user")) {
            next({ name: "login" })
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role.toLowerCase() == 'admin') {
                next('/dashboard')
            } else if (user.role.toLowerCase() == 'manager') {
                next('/managerdashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next('/supportdashboard')
            } else if (user.role.toLowerCase() == 'delivery man') {
                next('/deliverydashboard')
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next()
            } else if (user.role.toLowerCase() == 'seller') {
                next('/merchantdashboard')
            } else {
                next({ name: "login" })
            }
        }

    }
    else if (to.matched.some(record => record.meta.merchantAuth)) {
        if (!localStorage.getItem("user")) {
            next({ name: "login" })
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role.toLowerCase() == 'admin') {
                next('/dashboard')
            } else if (user.role.toLowerCase() == 'manager') {
                next('/managerdashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next('/supportdashboard')
            } else if (user.role.toLowerCase() == 'delivery man') {
                next('/deliverydashboard')
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next('/merchant-managerdashboard')
            } else if (user.role.toLowerCase() == 'seller') {
                next()
            } else {
                next({ name: "login" })
            }
        }

    }
    else if (to.matched.some(record => record.meta.deliveryAuth)) {
        if (!localStorage.getItem("user")) {
            next({ name: "login" })
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user.role.toLowerCase() == 'admin') {
                next('/dashboard')
            } else if (user.role.toLowerCase() == 'manager') {
                next('/managerdashboard')
            } else if (user.role.toLowerCase() == 'support') {
                next('/supportdashboard')
            } else if (user.role.toLowerCase() == 'delivery man') {
                next()
            } else if (user.role.toLowerCase() == 'merchant_manager') {
                next('/merchant-managerdashboard')
            } else if (user.role.toLowerCase() == 'seller') {
                next('/merchantdashboard')
            } else {
                next({ name: "login" })
            }
        }

    }
    else if (!localStorage.getItem("user")) {
        next({ name: "login" })
    }
});

export default router;
