import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
// import { htmlToPaper } from 'vue-html-to-paper'

import App from "./App.vue";
import router from "./router";
import store from "./store";
// import './plugins/base'
// import './plugins/chartist'
// import './plugins/vee-validate'
import vuetify from "./plugins/vuetify";
// import i18n from './i18n'
import axios from "axios";
window.axios = axios;

// axios.defaults.baseURL='https://tango99.herokuapp.com/'


axios.defaults.baseURL = "https://api.ambor.com.bd/ambor/"

//axios.defaults.baseURL = "https://tes.com.bd/mymarket/";
// axios.defaults.baseURL = 'https://tes.com.bd/vellycon/'
// axios.defaults.baseURL = 'https://tes.com.bd/bexmall/'
// axios.defaults.baseURL = 'https://tes.com.bd/demo/'
// axios.defaults.baseURL = 'http://178.128.106.15:8000/bexmall/'
// axios.defaults.baseURL = 'http://178.128.106.15:8000/bexmall/'

//axios.defaults.baseURL='https://tango99.herokuapp.com/'

// axios.defaults.baseURL = 'http://127.0.0.1:8000/bexmall/'

// axios.defaults.baseURL = 'http://68.183.231.4
// axios.defaults.baseURL = 'http://68.183.231.43:8000/'

// axios.defaults.baseURL = 'http://127.0.0.1:8000/mymarket/'
// axios.defaults.baseURL = 'http://128.199.114.154:8080/'

import DatetimePicker from "vuetify-datetime-picker";
// import vSelect from 'vue-select'

import VueApexCharts from "vue-apexcharts";

Vue.component("apexchart", VueApexCharts);

// Vue.component('v-select', vSelect)

Vue.use(DatetimePicker);

Vue.config.productionTip = false;

//import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
};
// Vue.use(VueHtmlToPaper, o);
Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet

new Vue({
  router,
  store,
  vuetify,
  // i18n,
  render: h => h(App)
}).$mount("#app");
